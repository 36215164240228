import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import * as LPF from './lp-contact-style';
import * as LP from "./landingpage-style";

const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID!;
const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID!;
const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY!;

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    gtag_report_conversion: (url?: string) => void;
  }
}

export const LpContact: React.FC = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: ''
  });

  const [acceptConditions, setAcceptConditions] = useState(false);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [sendEmailStatus, setSendEmailStatus] = useState('');

  // Define gtag_report_conversion function
  // useEffect(() => {
  //   window.gtag_report_conversion = function (url?: string) {
  //     const callback = function () {
  //       if (typeof url !== 'undefined') {
  //         window.location.href = url;
  //       }
  //     };
  //     if (window.gtag) {
  //       window.gtag('event', 'conversion', {
  //         'send_to': 'AW-16715305792/TvA9CPaj5tgZEMCmvaI-',
  //         'event_callback': callback,
  //       });
  //     }
  //     return false;
  //   };
  // }, []);

  //Define gtag_report_conversion function
  useEffect(() => {
    window.gtag_report_conversion = function (url?: string) {
      const callback = function () {
        if (typeof url !== 'undefined') {
          window.location.href = url;
        }
      };
      if (window.gtag) {
        window.gtag('event', 'conversion_event_submit_lead_form', {
          'event_callback': callback,
          'send_to': 'G-QFXV298GPJ',
        });
      }
      return false;
    };
  }, []);


  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};
    if (!formData.fullName) errors.fullName = '* Ditt namn är obligatoriskt';
    if (!formData.email) {
      errors.email = '* E-post är obligatoriskt';
    } else if (!validateEmail(formData.email)) {
      errors.email = 'E-post måste vara i ett giltigt format (t.ex. example@example.com)';
    }
    if (!formData.message) errors.message = '* Meddelande är obligatoriskt';
    if (!acceptConditions) errors.acceptConditions = '* Du måste acceptera villkoren för att fortsätta.';
    return errors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptConditions(e.target.checked);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    emailjs.send(serviceID, templateID, formData, { publicKey: publicKey }).then(
      (response) => {

        setFormErrors({});
        setSendEmailStatus('OK');
        // Reset the form
        setFormData({
          fullName: '',
          email: '',
          message: ''
        });
        setAcceptConditions(false);

        //Trigger Google conversion tracking after successful form submission
        if (typeof window.gtag_report_conversion === 'function') {
          window.gtag_report_conversion();
        }
      },
      (error) => {
        console.error('Misslyckades att skicka:', error);
        setSendEmailStatus('FAIL');
      }
    );
  };


  return (
    <LPF.LpContactContainer id="contactform">

      <LP.LpH1>Vi är redo när ni är - <LP.HighlightSpan $color="#E036E0">berätta</LP.HighlightSpan> mer så sätter vi på
        <LP.HighlightSpan $color="#40BFCC"> kaffet </LP.HighlightSpan> och börjar planera vår resa! </LP.LpH1>

      <LPF.LpForm onSubmit={handleSubmit} noValidate >

        <LPF.LpFormInputContainer>
          <LPF.LpLabel htmlFor="fullName">Namn*</LPF.LpLabel>
          <LPF.LpInputContacts
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            aria-label="För & efternamn"
            autoComplete="name"
            placeholder='För & efternamn'
          />
          {formErrors.fullName && <LPF.LpErrorMsg>{formErrors.fullName}</LPF.LpErrorMsg>}
        </LPF.LpFormInputContainer>


        <LPF.LpFormInputContainer>
          <LPF.LpLabel htmlFor="email">Hur kontaktar vi dig? *</LPF.LpLabel>
          <LPF.LpInputContacts
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            aria-label="E-post"
            autoComplete="email"
            placeholder='E-mail'
          />
          {formErrors.email && <LPF.LpErrorMsg>{formErrors.email}</LPF.LpErrorMsg>}
        </LPF.LpFormInputContainer>



        <LPF.LpFormInputContainer>
          <LPF.LpLabel htmlFor="message">Berätta, hur kan vi hjälpa dig? *</LPF.LpLabel>
          <LPF.LpTextarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            aria-label="Message"
          ></LPF.LpTextarea>
          {formErrors.message && <LPF.LpErrorMsg>{formErrors.message}</LPF.LpErrorMsg>}
        </LPF.LpFormInputContainer>

        <LPF.LpCheckboxContainer>
          <LPF.LpCheckboxInput
            type="checkbox"
            id="acceptConditions"
            name="acceptConditions"
            checked={acceptConditions}
            onChange={handleCheckboxChange}
            aria-label="Jag accepterar villkoren"
          />
          <LPF.LpCheckboxLabel htmlFor="acceptConditions">
            Jag accepterar <LPF.VillkorLink to="/privacy">villkoren</LPF.VillkorLink> *
          </LPF.LpCheckboxLabel>
        </LPF.LpCheckboxContainer>

        {formErrors.acceptConditions && <LPF.LpErrorMsg>{formErrors.acceptConditions}</LPF.LpErrorMsg>}

        <LP.SendButton type="submit">
          Skicka
        </LP.SendButton>

        {sendEmailStatus === 'OK' && <LPF.SendMsgText>Tack för din förfrågan, vi kontaktar dig inom kort!</LPF.SendMsgText>}
        {sendEmailStatus === 'FAIL' && <LPF.ErrorMsgText>Tyvärr gick inte meddelandet att skicka, testa igen eller kontakta oss på info@andtech.se</LPF.ErrorMsgText>}

      </LPF.LpForm>

    </LPF.LpContactContainer >
  );
}
